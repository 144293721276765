<template>
  <v-container fluid>
    <v-tabs v-model="tab" class="rounded-t">
      <v-tab key="info">
        {{ $t("analytics.lang_giftCard") }}
      </v-tab>
      <v-tab key="activity">
        {{ $t("erp.lang_giftCardActivity") }}
      </v-tab>

      <v-tabs-items touchless v-model="tab">
        <v-tab-item key="info" class="mt-5">
          <v-layout>
            <v-flex md12>
              <div>
                <v-layout>
                  <v-flex md4 mr-2 ml-2>
                    <v-text-field
                      outlined
                      @focus="showTouchKeyboard"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      v-model="giftCardBalance"
                      :label="$t('erp.lang_GiftCardValue')"
                      autocomplete="off"
                      disabled
                    ></v-text-field>
                  </v-flex>
                  <v-flex md4 mr-2 ml-2>
                    <v-text-field
                      outlined
                      @focus="showTouchKeyboard"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      v-model="issuingDate"
                      :label="$t('erp.lang_voucherIssuerID')"
                      autocomplete="off"
                      disabled
                    ></v-text-field>
                  </v-flex>
                  <v-flex md4 mr-2 ml-2>
                    <v-text-field
                      outlined
                      @focus="showTouchKeyboard"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      v-model="lastUse"
                      :label="$t('generic.lang_lastchange')"
                      autocomplete="off"
                      disabled
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout class="pb-2">
                  <v-flex class="text-right">
                    <v-btn text color="error" @click="goBack">
                      {{ $t("generic.lang_prev") }}
                    </v-btn>

                    <v-dialog persistent v-model="showDialog" width="400px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="success"
                          @click="showDialog = true"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ $t("erp.lang_changeGiftCardID") }}
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title>
                          {{ $t("erp.lang_changeGiftCardID") }}
                        </v-card-title>

                        <v-card-text class="pa-5">
                          <v-form lazy-validation ref="form" v-model="valid">
                            <v-text-field
                              @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              v-model="scannedGiftCardID"
                              :rules="[
                                (v) => !!v || $t('generic.lang_requiredField'),
                              ]"
                              autocomplete="off"
                              outlined
                              :label="$t('erp.lang_GiftCardNo')"
                            ></v-text-field>
                          </v-form>
                        </v-card-text>

                        <v-card-actions>
                          <v-btn
                            @click="showDialog = false"
                            :disabled="loading"
                            color="error"
                            text
                          >
                            {{ $t("generic.lang_cancel") }}
                          </v-btn>

                          <v-spacer></v-spacer>

                          <v-btn
                            @click="updateGiftCardID()"
                            :disabled="loading || !computedGiftCardID"
                            color="primary"
                            text
                          >
                            {{ $t("generic.lang_save") }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-btn
                      color="warning"
                      :disabled="loading"
                      :loading="loading"
                      @click="voidGiftCard()"
                    >
                      {{ $t("erp.lang_voidGiftCard") }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
        </v-tab-item>

        <v-tab-item key="activity">
          <Datatable
            :api-endpoint="ENDPOINTS.DATATABLES.ERP.VOUCHERACTIVITY"
            :data="params"
            :datatable-headers="datatableHeaders"
            :excel-columns="excelColumns"
            excel-file-name="giftCard invoice"
            @displayEntry="displayEntry"
            show-display-buttons
          >
            <template v-slot:item.total="{ item }">
              {{ item.total | currency }}
            </template>
          </Datatable>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import { Events } from "@/plugins/events";
import { ENDPOINTS } from "@/config";
import mixin from "../../../../mixins/KeyboardMixIns";
import Datatable from "../../../datatable/Datatable";

export default {
  name: "GiftCardDetailsComponent",

  components: {
    Datatable,
  },

  mixins: [mixin],

  data: () => {
    return {
      ENDPOINTS,
      tab: 0,
      valid: true,
      loading: false,
      showDialog: false,
      giftCardBalance: "",
      issuingDate: null,
      lastUse: null,
      scannedGiftCardID: "",
    };
  },
  computed: {
    computedGiftCardID() {
      if (this.scannedGiftCardID.includes("GF$")) {
        let giftCardID = this.scannedGiftCardID.split("GF$");
        return giftCardID[1];
      }

      return "";
    },
    params() {
      return {
        voucherUUID: `GF$${this.$route.params.id}`,
      };
    },
    datatableHeaders() {
      return [
        {
          text: this.$t('generic.lang_id'),
          align: 'left',
          value: "id",
          hide: true
        },
        {text: this.$t('generic.lang_id'), value: "ident", sort: "desc"},
        {text: this.$t('generic.lang_time') + " / " + this.$t('generic.lang_date'), value: "date"},
        {text: this.$t('generic.lang_seller_id'), value: "sellerid"},
        {text: this.$t('generic.lang_customer'), value: "cust"},
        {text: this.$t('erp.lang_totalsolditems'), value: "saleItems"},
        {text: this.$t('erp.lang_ware_total'), value: "total"},
        {text: this.$t('accounting.lang_paymenthod'), value: "paymentType"},
        {text: this.$t('generic.lang_TableNo'), value: "tableNo", hide: false},
        {text: this.$t('generic.lang_parMaison'), value: "parMaison"},
        {text: this.$t('generic.lang_cashierID'), value: "cashierID"},
        {text: this.$t('generic.lang_receipttype'), value: "type"},
      ];
    },
    excelColumns() {
      return [
        {label: this.$t('generic.lang_id'), field: "ident"},
        {label: this.$t('generic.lang_time') + " / " + this.$t('generic.lang_date'), field: "date"},
        {label: this.$t('generic.lang_seller_id'), field: "sellerid"},
        {label: this.$t('generic.lang_customer'), field: "cust"},
        {label: this.$t('erp.lang_totalsolditems'), field: "saleItems"},
        {label: this.$t('erp.lang_ware_total'), field: "total"},
        {label: this.$t('accounting.lang_paymenthod'), field: "paymentType"},
        {label: this.$t('generic.lang_TableNo'), field: "tableNo"},
        {label: this.$t('generic.lang_parMaison'), field: "parMaison"},
        {label: this.$t('generic.lang_cashierID'), field: "cashierID"},
        {label: this.$t('generic.lang_receipttype'), field: "type"},
      ];
    }
  },
  methods: {
    goBack() {
      this.$router.push("/erp/baseData/giftCard");
    },
    displayEntry(entry) {
      this.$router.push("/accounting/DisplayInvoice/" + entry.id);
    },
    getGiftCard() {
      let id = this.$route.params.id;

      this.axios
        .post(ENDPOINTS.ERP.GIFTCARD.GET, {
          giftCardUUID: id,
        })
        .then((res) => {
          if (res.status === 200) {
            this.giftCardBalance =
              res.data.formfillData.textFields.voucherBalance;
            this.issuingDate = res.data.formfillData.textFields.issuingDate;
            this.lastUse = res.data.formfillData.textFields.lastUse;
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        });
    },
    updateGiftCardID() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;

      this.axios
        .post(ENDPOINTS.ERP.GIFTCARD.UPDATE, {
          giftCardUUID: this.computedGiftCardID,
        })
        .then((res) => {
          if (res.data.status === true) {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_success"),
              color: "success",
            });

            this.$refs.form.reset();
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    voidGiftCard() {
      let uuid = this.$route.params.id;

      this.$swal({
        title: this.$t("erp.lang_voidGiftCard") + "?",
        text: this.$t("generic.lang_voidGiftCardTextMSG"),
        icon: "warning",
        cancelButtonText: this.$t("generic.lang_cancel"),
        confirmButtonText: this.$t("generic.lang_delete"),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.loading = true;

          this.axios
            .post(ENDPOINTS.ERP.GIFTCARD.VOID, {
              uuid: uuid,
            })
            .then((res) => {
              if (res.data.status === true) {
                Events.$emit("showSnackbar", {
                  message: this.$t("generic.lang_success"),
                  color: "success",
                });

                this.goBack();
              } else {
                Events.$emit("showSnackbar", {
                  message: this.$t("generic.lang_errorOccurred"),
                  color: "error",
                });
              }
            })
            .catch(() => {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_errorOccurred"),
                color: "error",
              });
            })
            .finally(() => {
              this.loading = false;
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
  },
  mounted() {
    this.getGiftCard();
  },
};
</script>
