<template>
  <div>
    <page-title
      :heading="$t('analytics.lang_giftCard')"
      :subheading="$t('analytics.lang_giftCard')"
      :icon="icon"
      show-previous-button
      url-previous="/erp/baseData/giftCard"
    ></page-title>
    <div class="app-main__inner">
      <gift-card-details-component />
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../../Layout/Components/PageTitle.vue";
import GiftCardDetailsComponent from "../../../../components/erp/baseData/giftCard/GiftCardDetailsComponent";

export default {
  components: {
    PageTitle,
    GiftCardDetailsComponent,
  },
  props: ["id"],
  data: () => ({
    icon: "pe-7s-box1 icon-gradient bg-tempting-azure",
  }),
};
</script>
